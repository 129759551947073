import request from '@/utils/request'

const userApi = {
    SendSms: '/User/contactfreetrial',
    Tag: '/User/getdiagnosticsitetag',
    upload:'/file/uploadfile',
    uploadForm:'/User/saveuserdata'

}

// 单独上传文件
export function uploadfile(){
    return userApi.upload
}

// 提交表单
export function postForm(data) {
    return request({
        url: userApi.uploadForm,
        method: 'post',
        data
    })
}

// 提交试用者信息
export function postfreetrial(data) {
    return request({
        url: userApi.SendSms,
        method: 'post',
        data
    })
}

// 获取Tag
export function getTag(parameter) {
    return request({
        url: userApi.Tag,
        method: 'get'
    })
}
